<template>
  <div class="auth__form__content" id="auth-login">
    <r-card>
      <h2 class="has-text-centered">Please fill out the company form below</h2>
      <div class="columns">
        <div class="column is-12">
          <validation-provider name="Name" rules="required" v-slot="{ errors }">
            <b-field
              label="Name*"
              :type="errors[0] && 'is-danger'"
              :message="errors[0]"
            >
              <b-input
                v-model="form.name"
                placeholder="e.g: PT. Roketin Kreatif Teknologi"
              ></b-input>
            </b-field>
          </validation-provider>
        </div>
      </div>
      <div class="columns">
        <div class="column is-offset-2 is-3">
          <b-button @click="$emit('close')" expanded type="is-primary" outlined>
            Cancel
          </b-button>
        </div>

        <div class="column is-offset-2 is-3">
          <b-button @click="save()" expanded>Create</b-button>
        </div>
      </div>
    </r-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: '',
      },
    }
  },

  methods: {
    save() {
      this.$store
        .dispatch('company/storeCompany', this.form)
        .then(() => {
          this.$emit('fetch')
          this.$emit('close')
        })
        .catch((error) => {
          this.$emit('close')
          console.log(error)
        })
    },
  },
}
</script>
