<template>
  <div class="columns">
    <div class="column is-half is-offset-one-quarter">
      <r-card class="auth__form">
        <div class="auth__form__content" id="auth-login">
          <h2 class="has-text-centered is-size-2">Welcome to SPACE ID</h2>
          <div class="has-text-centered has-text-weight-semi-bold is-size-2">
            Please Select Your Company
          </div>
          <div
            class="box field pointer-click"
            v-for="(userCompany, key) in userCompanies"
            :key="key"
          >
            <div class="is-size-5" @click="select(userCompany.company.id)">
              {{ userCompany.company.name }}
            </div>
          </div>
          <b-button
            @click="openModal()"
            expanded
            v-if="getVueAppUseRegister == 'yes'"
          >
            Create New Company
          </b-button>
          <!-- Modal Form Company -->
          <b-modal
            v-model="isFormCompanyModalActive"
            :can-cancel="false"
            v-if="getVueAppUseRegister == 'yes'"
          >
            <template #default="props">
              <FormCompany
                @fetch="fetchCompaniesDataByUser"
                @close="props.close"
              />
            </template>
          </b-modal>
        </div>
      </r-card>
    </div>
  </div>
</template>

<script>
import FormCompany from '../../../layouts/form/company.vue'
import ApiService from '@/services/common/api.service'

export default {
  components: {
    FormCompany,
  },
  data() {
    return {
      userCompanies: [],
      isFormCompanyModalActive: false,
      serviceId: this.$route.query.service_id,
    }
  },
  created() {
    this.fetchCompaniesDataByUser()
  },
  computed: {
    getVueAppUseRegister() {
      return process.env.VUE_APP_USE_REGISTER
    },
  },
  methods: {
    openModal() {
      this.isFormCompanyModalActive = true
    },
    fetchCompaniesDataByUser() {
      this.$store
        .dispatch('company/fetchCompaniesDataByUser')
        .then((response) => {
          this.userCompanies = response.data.data
          if (
            this.userCompanies.length === 1 &&
            this.userCompanies[0].role !== 'Owner'
          ) {
            let companyId = this.userCompanies[0].company.id
            this.select(companyId)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    select(id) {
      if (this.serviceId !== undefined) {
        this.$store
          .dispatch('company/selectCompany', {
            id: id,
            serviceId: this.serviceId,
          })
          .then((response) => {
            window.location.href = response.data.service.domain
          })
          .catch((error) => {
            console.log(error)
          })
      } else {
        this.$store
          .dispatch('company/selectCompany', { id: id, serviceId: '' })
          .then(async (response) => {
            if (response.data.company.users.length < 3) {
              ApiService.reInitAuthorization()
              await this.$store.dispatch('auth/getCurrentUser')
              let role = this.$store.getters['auth/currentUser'].user.role

              if (role == 'member') {
                this.$router.push('/select/product')
              } else {
                this.$router.push('/select/company/add-user')
              }
            } else {
              this.$router.push('/select/product')
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
  },
}
</script>
